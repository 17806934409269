import React, {useState} from "react";
import * as styles from "src/styles/Mc.module.css";
import { SEO, Picture } from "src/components/commonComponents";
import Modal from 'react-modal';
import ROICalculator from "src/components/blocks/ROICalculator";
import mcHeroBanner from "../../../static/staticPages/mc-hero.jpg"
import lucernexHero from "../../../static/staticPages/Migration-Lucernex/Lx-NewHero.jpg";
import featureLogo from "../../../static/staticPages/featureLogo.svg"
import listCheckLogo from "../../../static/staticPages/mc-product/list-check-solid.svg";
import fileCircleLogo from "../../../static/staticPages/mc-product/file-circle-check-solid.svg";
import fileCertificateLogo from "../../../static/staticPages/mc-product/file-certificate-solid.svg";
import boxCircleCheck from "../../../static/staticPages/mc-product/box-circle-check-solid.svg";
import folderMagnify from "../../../static/staticPages/mc-product/folder-magnifying-glass-solid.svg";
import gaurgeMax from "../../../static/staticPages/mc-product/gauge-max-solid.svg";
import minnesotaTwins from "../../images/logos/accruent-partners/MinnesotaTwins_Logo.png";

import listCheckSolid from "../../../static/staticPages/Migration-Lucernex/list-check-solid.svg";
import buildingIcon from "../../../static/staticPages/Migration-Lucernex/buildings-drb.svg";
import employeeOpen from "../../../static/staticPages/Migration-Lucernex/envelope-open-dollar.svg";
import clipboardCheck from "../../../static/staticPages/Migration-Lucernex/clipboard-list-check.svg";
import objectInteresct from "../../../static/staticPages/Migration-Lucernex/objects-intersect.svg";
import chartLine from "../../../static/staticPages/Migration-Lucernex/chart-line.svg";

import genesco from "../../../static/staticPages/Migration-Lucernex/update-logos/Genesco-Text-black.png";
import banfieldLogo from "../../../static/staticPages/Migration-Lucernex/update-logos/Banfield_Pet_Hospital-text-black.png"
import amcLogo from "../../../static/staticPages/Migration-Lucernex/amc.png";
import helzbergLogo from "../../../static/staticPages/Migration-Lucernex/update-logos/Helzberg_Diamonds-text-black.png";
import genescoImage from "../../../static/staticPages/Migration-Lucernex/update-logos/Genesco-Text-black.png";


import lxContractsLogo from "../../../static/staticPages/Migration-Lucernex/LX Contracts-Color-Logo.svg";
import lxMarketLogo from "../../../static/staticPages/Migration-Lucernex/LX-Market-Sites-Color-Logo.svg";
import accruentProjectsLogo from "../../../static/staticPages/Migration-Lucernex/LX Projects-Color-Logo.svg";
import lxTransactionLogo from "../../../static/staticPages/Migration-Lucernex/LX-Transaction-Color-Logo.svg";

import lxIcon from "../../../static/staticPages/Migration-Lucernex/lx-icon.png";

import transformerBolt from "../../../static/staticPages/mc-product/transformer-bolt-solid.svg";
import mobileSolid from "../../../static/staticPages/mc-product/mobile-solid.svg";
import fileChartColumn from "../../../static/staticPages/mc-product/file-chart-column-solid.svg";
import objectIntersect from "../../../static/staticPages/mc-product/object-intersect-solid.svg";
import warehouseFull from "../../../static/staticPages/mc-product/warehouse-full-solid 22.svg";

import lucernexLogo from "../../../static/staticPages/lucernex-logo.svg"


import charLineLogom from "../../../static/staticPages/mc-product/chart-line-solid.svg";
import oilWell from "../../../static/staticPages/mc-product/oil-well-solid.svg";
import prescriptionBottle from "../../../static/staticPages/mc-product/prescription-bottle-medical-solid.svg";
import mcLogoN from "../../../static/staticPages/mc-logo.png"
import service4 from "../../../static/staticPages/service4.svg"
import service2 from "../../../static/staticPages/lucernex-logo.png"
import redeyeLogo from "../../../static/staticPages/redeye_logo.png"
import Dot from "../../../static/staticPages/Dot.svg"
import Play from "../../../static/staticPages/Play.svg"
import Play1 from "../../../static/staticPages/Play1.svg"
import arrowDownBlue from "../../../static/staticPages/arrowDownBlue.svg"
import arrowUpBlue from "../../../static/staticPages/arrowUpBlue.svg"
import arrowRightBlue from "../../../static/staticPages/arrowRightBlue.svg"
import benefit1 from "../../../static/staticPages/benefit1.svg"
import benefit2 from "../../../static/staticPages/benefit2.svg"
import benefit3 from "../../../static/staticPages/benefit3.svg"
import benefit4 from "../../../static/staticPages/benefit4.svg"
import benefit5 from "../../../static/staticPages/cook_black.png"
import benefit6 from "../../../static/staticPages/benefit6.svg"
import masters from "../../../static/Assets_Version_A/logos/master-gallery.png"
import tick from "../../../static/staticPages/tick.png"
import fullWidthBannerMc1 from "../../../static/staticPages/encore_wire.png"
import logo1 from "../../../static/staticPages/logo1.svg"
import logo2 from "../../../static/staticPages/logo2.svg"
import logo3 from "../../../static/staticPages/cook_white.png"
import logo4 from "../../../static/staticPages/logo4.svg"
import logo5 from "../../../static/staticPages/logo5.svg"
import logo6 from "../../../static/staticPages/logo6.svg"

import amcWhite from "../../../static/staticPages/Migration-Lucernex/lx-images/amc-logo-white.png";
import banfieldWhite from "../../../static/staticPages/Migration-Lucernex/update-logos/Banfield_Pet_Hospital-text-white.png";
import genescoWhite from "../../../static/staticPages/Migration-Lucernex/update-logos/Genesco-Text-white.png";
import helzWhite from "../../../static/staticPages/Migration-Lucernex/update-logos/Helzberg_Diamonds-text-white.png";


import mastersWhite from "../../../static/staticPages/masters_gray.png"
import mcHeroBanner1 from "../../../static/staticPages/mcHeroBanner1.png"
import arrowRight from "../../../static/staticPages/arrowRight.svg"
import resource1 from "../../../static/staticPages/Migration-Lucernex/resource1.jpg";
import resource2 from "../../../static/staticPages/Migration-Lucernex/resource2.jpg";
import resource3 from "../../../static/staticPages/Migration-Lucernex/resource3.jpg";
import resource4 from "../../../static/staticPages/Migration-Lucernex/resource4.jpg";
import resource5 from "../../../static/staticPages/Migration-Lucernex/resource5.jpg";

import design1 from "../../../static/staticPages/design1.png"
import design2 from "../../../static/staticPages/design2.svg"
import design3 from "../../../static/staticPages/design3.svg"
import mcLogo from "../../../static/staticPages/mc-logo.png"
import { useHeroImages } from "src/hooks/useHeroImages";
import { getPageDataJsonPath } from "src/utils";

import logoStrip1 from "../../../static/staticPages/emsLogoStrip1.svg"
import logoStrip2 from "../../../static/staticPages/emsLogoStrip2.png"
import logoStrip3 from "../../../static/staticPages/emsLogoStrip3.png"
import logoStrip4 from "../../../static/staticPages/emsLogoStrip4.svg"
import { useFormImages } from "src/hooks/useFormImages";
import { ContentMarketoForm } from "src/components/commonComponents/forms/ContentMarketoForm";
import getApp from "../../../static/staticPages/Migration-Lucernex/get-app.png";
import g2 from "../../../static/staticPages/Migration-Lucernex/g2.png";
import softwareAdvice from "../../../static/staticPages/Migration-Lucernex/software-advice.png";
import capterra from "../../../static/staticPages/Migration-Lucernex/captera.svg";


const data = {
  ROICalculator: {
    "type": {
      "id": "roi_calculator"
    },
    "uuid": "78184b28-8a91-47bf-8b65-be5c530fc2c7",
    "nid": 14499,
    // "title": "ROI Calculator",
    "fieldHideTitle": false,
    "fieldShowTitleFullWidth": null,
    "fieldRoiCalculatorId": "dl_calculator",
    "fieldEmbedCode": "<script src=\"https://jamaica.value-cloud.com/embed.js\" width=\"100%\" height=\"100%\" calc=\"501bb22b29fdc278578900823a3f6c4f\" currency_code=\"USD\" lang=\"en_US\" company=\"company_name\"></script>"
  },
  heroBanner:{
    title: 'Lucernex: Real Estate Management Software',
    description:"Streamline every phase of your property journey with Accruent’s versatile real estate management software ",
    link1: {
      url: '/request-demo',
      text: 'Request a Demo'
    },
    link2: {
      url: '/demo',
      text: 'Interactive Demos'
    },
    image: {
      url: lucernexHero,
      altText: 'Three real estate professionals on site discuss a construction project, reviewing plans and coordinating next steps.'
    },
    logo: {
      url: lucernexLogo,
      altText: "Accruent Lucernex logo"
    }
  },
  logoStrip:[
    {
      url: capterra,
      altText: 'Lucernex Capterra rating 4 stars indicating high user satisfaction'
    },
    {
      url: getApp,
      altText: 'Lucernex GetApp rating 4 stars indicating high user satisfaction'
    },
   
    {
      url: softwareAdvice,
      altText:'Lucernex SoftwareAdvice rating 4 stars indicating high user satisfaction'
    },
    {
      url: g2,
     altText:'Lucernex G2 rating 4 stars indicating high user satisfaction'
    }
  ],
  benefits: {
    title: "Real Estate Management, Simplified",
    description: 'Take your operations to the next level with comprehensive real estate and equipment management for site planning, construction, lease administration, and transaction management',
    items: [
      {
        logoUrl: listCheckSolid,
        altText: "Checklist icon representing FASB/IASB compliance",
        title: "Maintain FASB/IASB Compliance",
        description: "Ensure compliance is achieved and maintained for current and upcoming lease accounting standards. Lucernex has a 100% success rate getting clients into compliance with standards like FASB ASC 842, IASB IFRS 16 and GASB 87",
        // link:{
        //   text:'Explore regulatory compliance',
        //   url:'/'
        // }
      },
      {
        logoUrl: buildingIcon,
        altText: "Buildings icon",
        title: "Effectively Manage Leases ",
        description: "Get transparency into your entire portfolio so all relevant personnel can access needed information. Quickly calculate lease and expense obligations, identify underperforming assets, and ensure team efficiency",
        // link:{
        //   text:'Explore asset availability',
        //   url:'/'
        // }
      },
      {
        logoUrl: employeeOpen,
        altText: "Open envelope icon",
        title: "Decrease Lost Revenue Days ",
        description: "By automating data collection and centralizing your data with Lx Market Planning and Site Selection, you can simplify portfolio management – which in turn can help you get sites approved and start generating revenue",
        // link:{
        //   text:'Explore quality control',
        //   url:'/'
        // }
      },
      {
        logoUrl: clipboardCheck,
        altText: "Clipboard checklist icon",
        title: "Streamline Project Management",
        description: "Manage new location construction, openings, and capital improvement processes with data-driven insights. Effectively allocate resources, control costs, increase cross-functional collaboration, and save time",
        // link:{
        //   text:'Explore inventory management',
        //   url:'/'
        // }
      },
      {
        logoUrl: objectInteresct,
        altText: "Integrate icon",
        title: "Optimize Your Real Estate Portfolio",
        description: "Save time and resources by automating your real estate selection and site approval process. That way, you can make better decisions and optimize your portfolio",
        // link:{
        //   text:'Explore operation visibility',
        //   url:'/'
        // }
      },
      {
        logoUrl: chartLine,
        altText: "Line chart icon representing forecasts",
        title: "Improve Forecasting Capabilities ",
        description: "Use Lucernex's robust reporting and analytics to forecast, budget, and allocate funds effectively, avoiding the need for multiple systems or manual reporting",
        // link:{
        //   text:'Explore operation visibility',
        //   url:'/'
        // }
      }
    ],
    info:{
      title: "Trusted by industry leaders across 75+ countries",
      images:[
      {
        url:amcLogo,
        altText:"AMC Theaters logo"
      },
      {
        url: genesco,
        altText: "Genesco logo"
      },
      {
        url:banfieldLogo,
        altText:"Banfield Pet Hospital logo"
      },
      {
        url:helzbergLogo,
        altText:"Helzberg Logo"
      },
    //   {
    //     url:masters,
    //     altText:"Masters Gallery Food"
    //   }
    ],
    link: {
      text:"Request a demo",
      url:'/request-demo'
    }
    }
  },
  threeColTwoRowComp: {
    title:"Explore the Lucernex Suite",
    description: "Why settle for a one-size-fits-all solution? Lucernex has four distinct products that cover everything from site planning and construction to lease administration with precision and ease",
    items:[
      {
        logoUrl: lxIcon,
        altText: "Accruent Lx Contracts icon logo",
        title:"Lx Contracts",
        description: "This lease accounting software is crafted to keep you in line with the latest financial reporting standards. It simplifies lease and expense calculations while helping you spot underperforming assets, ensuring your team operates at peak efficiency",
        linkText: "Explore Lx Contracts ",
        link: "https://www.accruent.com/products/lucernex/lx-contracts"
      },
      {
        logoUrl: lxIcon,
        altText: "Accruent Lx Market & Sites icon logo",
        title:"Lx Markets & Sites",
        description: "Leverage the power of real-time analytics with our market planning and site selection software. It's built to shorten site selection cycles, giving you the insights to understand market conditions, make informed decisions, and wrap up negotiations swiftly",
        linkText: "Explore Lx Markets & Sites",
        link: "https://www.accruent.com/products/lucernex/lx-markets-sites"
      },
      { 
        logoUrl: lxIcon,
        altText: "Accruent Lx Projects icon logo",
        title:"Lx Projects",
        description: "Manage your construction projects without a hitch using our robust real estate property management software. Whether it's launching new retail spaces or overseeing capital improvements, Lx Projects offers data-driven insights to keep everything on track and within budget",
        linkText: "Explore Lx Projects",
        link: "https://www.accruent.com/products/lucernex/lx-projects"
      },
      {
        logoUrl: lxIcon,
        altText: "Accruent Lx Transaction Management icon logo",
        title:"Lx Transaction Management",
        description: "Seamlessly connect contracts, site planning, projects, and transactions with our integrated solution. Lx Transaction Management is a key component of your IWMS, streamlining the real estate transaction lifecycle and empowering you to manage with confidence",
        linkText: "Explore Lx Transaction Management",
        link: "https://www.accruent.com/products/lucernex/lx-transaction-management"

      },
    //   {
    //     logoUrl: warehouseFull,
    //     altText: "Warehouse icon",
    //     title:"Simplified Inventory Management ",
    //     description: "Lower inventory costs, increase speed-to-repair, and easily track and visualize your inventory data with specialized equipment maintenance software",
    //   }
    ],
    // link:{
    //   text: 'Explore the many benefits Accruent software offers',
    //   url: '/request-demo'
    // }
  },
  fullWidthBanner:{
    image: {
      url: genescoImage,
      altText:"Genesco logo"
    },
    title:"Regional VP of Real Estate, Genesco",
    description: "Our lease agreements typically are built off of a pro-rata share of a mall. Lucernex easily handles [this] while improving the thoroughness of our lease audits to ensure we capture the highest rent savings possible.",
    name: "Kimberly Williams",
    position: "Regional VP of Real Estate"
    // companyName: " Encore Wire Corporation"
  },
  logoSlider:{
    logoList:[
      
      {
        url: amcWhite,
        altText:'AMC Theaters logo'
      },
      {
        url: genescoWhite,
        altText:'Genesco logo'
      },
      {
        url: banfieldWhite,
        altText:"Banfield Pet Hospital logo"
      },
      {
        url: helzWhite,
        altText:'Helzberg Logo'
      }
    ]
  },
  features: {
    title: 'Simplify Your Lease and Real Estate Management Once and For All',
    description: "From identifying a site to overseeing construction to managing complex leases and transactions, Lucernex has you covered",
    logo:{
      url: lucernexLogo,
      altText: 'Lucernex logo'
    },
    items: [
      {
        logoUrl: fileChartColumn,
        altText: "File chart icon",
        title: "Comprehensive Integrations ",
        description: "Easily connect to ERPs, accounting systems, BI tools, financial management systems, and more to easily maintain compliance and generate comprehensive insights",
        // link:{
        //   text:'Explore Manufacturing',
        //   url:'/industries/manufacturing'
        // }
      },
      {
        logoUrl: mobileSolid,
        altText: "Mobile icon",
        title: "Advanced Dashboards and Analytics",
        description: "Use real-time, comprehensive data to make informed site selection decisions and maximize speed to market",
        // link:{
        //   text:'Explore Oil & Gas',
        //   url:'/industries/utilities/oil-gas'
        // }
      },
      {
        logoUrl: boxCircleCheck,
        altText: "Check box icon",
        title: "Real Estate Planner",
        description: "Strategically plan where you'd like to expand and monitor your real estate targets using the Lucernex Real Estate Planner",
        // link:{
        //   text:'Explore Pharmaceutical',
        //   url:'/industries/manufacturing/process-manufacturing/pharmaceutical'
        // }
      }
    ]
  },
  heroBanner1:{
    title: 'Look under the hood',
    description:'Learn more about our software, get your questions answered, and find out how it can complement your current technology and processes',
    link1: {
      url: '/request-demo',
      text: 'Request a demo'
    },
    link2: {
      url: '/contactus',
      text: 'Contact Sales'
    },
    image: {
      url: mcHeroBanner1,
      alt: 'Three customers asking questions and learning more about our software in a collaborative environment'
    }
  },
  LinkStrip: {
    url:'/',
    text:'Calculate what impact Maintenance Connection will have on your business',
    link1:{
      url:'/',
      text:'Calculate Maintenance Connection Pricing'
    },
    link2:{
      url:'/',
      text:'Calculate what impact Lucernex will have on your business'
    }
  },
  resources: {
    title: "Some resources you might find interesting",
    link:{
      url:'/resources',
      text:"Explore Accruent resources"
    },
    resourceList:{
      resource1: {
        image:{
          url:resource1,
          altText: 'AMC Theaters Uses Lucernex For Streamlined Lease and Rent Payments'
        },
        title:"AMC Theaters Uses Lucernex For Streamlined Lease and Rent Payments",
        description:"ear from AMC Theaters' Nick LaBruzzo about how the company utilizes Lucernex and Lx Contracts for their rent and lease management needs.",
        link: 'https://www.accruent.com/resources/case-studies/amc-theaters-uses-lucernex-streamlined-lease-and-rent-payments',
        button:{
          text: 'Case Study',
        }
      },
      resource2: {
        image:{
          url:resource2,
          altText: 'The Ongoing Real Estate Management Trends Shaping the Market By 2028'
        },
        title:"The Ongoing Real Estate Management Trends Shaping the Market By 2028",
        description:"Explore the ongoing real estate management trends that are shaping the market by 2028, including changing work patterns, sustainability initiatives, the impact of high-interest rates, and more.",
        link: 'https://www.accruent.com/resources/blog-posts/the-ongoing-real-estate-management-trends-shaping-the-market-by-2028',
        button:{
          text: 'Blog',
        }
      },
      list:[
        {
          image:{
            url:resource3,
            altText: 'Retail Revolution: Trends Shaping Retail In 2024 And Beyond'
          },
          title:"Retail Revolution: Trends Shaping Retail In 2024 And Beyond",
          link: 'https://www.accruent.com/resources/ebooks/retail-revolution-trends-shaping-retail-in-2024-and-beyond',
          button:{
            text: 'E-book',
          }
        },
        {
          image:{
            url:resource4,
            altText: 'Lucernex Lease Administration & Accounting'
          },
          title:"Lucernex Lease Administration & Accounting",
          link: 'https://www.accruent.com/resources/brochures/lucernex-lease-administration-accounting',
          button:{
            text: 'Brochure',
          }
        },
        {
          image:{
            url:resource5,
            altText: "Optimizing Your Company's Space and Facility Management in 2024 and Beyond"
          },
          title:"Optimizing Your Company's Space and Facility Management in 2024 and Beyond",
          link: 'https://www.accruent.com/resources/white-papers/optimizing-your-companys-space-and-facility-management-2024-and-beyond',
          button:{
            text: 'White Paper',
          }
        }
      ]
    },
    walkthrough: {
      title:"Walkthrough demonstrations",
      items:[
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"Managing the Real Estate Lifecycle ",
          description: "Identify and select new sites, oversee construction, manage lease scenarios, and make stronger transaction management decisions",
          link: 'https://accruent.wistia.com/medias/d2622a6kwh',
          wistiaId: 'd2622a6kwh'
        },
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"Lx Projects Mobile ",
          description: "Enable construction teams to easily manage key tasks anywhere, anytime with Lx Projects mobile app ",
          link: 'https://accruent.wistia.com/medias/s8hebygdq8 ',
          wistiaId: 's8hebygdq8'
        },
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"Lucernex Update for 2024 ",
          description: "Learn more about Lucernex capabilities coming to you in 2024 and beyond ",
          link: 'https://accruent.wistia.com/medias/xgv9wk075f ',
          wistiaId: 'xgv9wk075f'
        },
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"Real Estate Transaction Management ",
          description: "Dive into the Lucernex Real Estate Transaction Management module ",
          link: 'https://accruent.wistia.com/medias/h085i6ekyx',
          wistiaId: 'h085i6ekyx'
        }
      ]
    }
  },
  support:{
    logo:{
      url: lucernexLogo,
      altText: 'Accruent Maintenance Connection Logo'
    },
    title: 'Frequently Asked Questions',
    list: [
      {
        title: 'What types of real estate portfolios does Lucernex support?',
        descList: [
          {
            text: '<span>Lucernex is the right solution for real estate portfolios of various sizes and different property types. It is capable of handling both commercial and residential portfolios.</span>'
          },
          {
            text: '<span>From site planning to construction, lease management to building maintenance, Lucernex supports organizations at any phase of their real estate management process.</span>'
          },
          {
            text: `<span>We support various commercial sectors as well, including healthcare, industrial, and educational facilities.</span>`
          },
        ]
      },
      {
        title: 'How does Lucernex improve property management processes?',
        descList: [
          {
            text: '<span>The property management process can be all-consuming without robust real estate management software in your corner.</span>'
          },
          {
            text: '<span>Track and manage all property activities from the acquisition phase to routine maintenance checks. Stay on top of your portfolio with frequent alerts sent to your phone. Automate lease-related processes including tracking contracts, critical dates, and rent payments.</span>'
          },
          {
            text: '<span>Analyze property workflows using real-time reporting and analytics, accessible from any device.</span>'
          }
        ]
      },
      {
        title: 'Can Lucernex integrate with other business systems?',
        descList: [
          {
            text: '<span>Lucernex recognizes the need for seamless integration with lease administration and accounting tools commonly used in the real estate industry.</span>'
          },
          {
            text: '<span>Centralized documentation is a focal point of Lucernex’s approach to real estate management, so integration is an important component of this focus.</span>'
          },
          {
            text: '<span>Seamless integration reduces the likelihood of errors and improves the accuracy of reporting. Users can pull data in from multiple sources to easily gauge KPIs. Lucernex easily integrates with ERPs and other business applications geared towards simplicity.</span>'
          },
        ]
      },
      {
        title: 'What benefits does Lucernex offer for financial management in real estate?',
        descList: [
          {
            text: '<span>Lucernex’s range of benefits include financial reporting, expense tracking, and comprehensive forecasting. Organizations can configure the platform to display the exact metrics which will move the needle.</span>'
          },
          {
            text: '<span>Lower the chances of overrunning your budgetary goals with a proven fiscal management tool. Breakdown all critical items, including lease terms, rental income, and anticipated or unforeseen expenses.</span>'
          },
          {
            text: '<span>Integrate Lucernex with your favorite accounting software and do not miss a beat.</span>'
          },
          
        ]
      },
      {
        title: 'Will Lucernex help increase my real estate portfolio?',
        descList: [
          {
            text: '<span>Thanks to the ability to keep your properties and workflows better organized, you will have more time to pursue deals and grow your real estate portfolio.</span>'
          },
          {
            text: '<span>Lucernex takes many logistical concerns off your plate, automating tasks and giving you more freedom to grow your business.</span>'
          },
          {
            text: '<span>Keep better track of your financial data with the Lucernex real estate management software so you know exactly what’s possible.</span>'
          }
        ]
      }

    ],
    contact: {
      title: 'Don’t see your question? Let us help you',
      link: {
        url:'/contactus',
        text:'Contact support'
      }
    }
  },
  design:{
    title:"Products designed to integrate your world",
    description: "Accruent software allows you to fully optimize the lifecycle of your asset and facilities, offering connected workflows, integrated experiences and data-backed insights.",
    link: {
      url:'/products',
      text:'Explore all Accruent products'
    },
    productList:[
      {
        url: design1,
        altText: 'Accruent EMS Logo',
        link:'/products/ems'
      },
      {
        url:redeyeLogo,
        altText: 'Accruent Redeye Logo',
        link:'/products/redeye'
      },
      {
        url: mcLogo,
        altText: 'Accruent Maintenance Connection Logo',
        link:'/products/maintenance-connection'
      },
      {
        url: service2,
        altText: 'Accruent Lucernex Logo',
        link:'/products/lucernex'
      }
    ]
  }
}

const HeroBanner = (heroImages) => {
  return <>
     <div className={styles.heroContainer}>
        <div className={styles.leftWrapper}>
          <div className={styles.heroLogoTitleWrapper}>
            <img src={data?.heroBanner?.logo?.url} alt={data?.heroBanner?.logo?.altText} className={styles.heroLogo}></img>
            <h1 className={styles.heroTitle}>
              {data?.heroBanner?.title}
            </h1>
          </div>
          <p className={styles.heroDesc}>{data?.heroBanner?.description}</p>
          <div className={styles.heroBtnWrapper}>
            <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.heroBanner?.link1?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src= {Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner?.link2?.url} className={styles.heroBtn2Wrapper}>
              <span className={styles.heroBtn2Logo}><img src= {Play}></img></span>
              <span className={styles.heroBtn2Text}>{data?.heroBanner?.link2?.text}</span>
            </a>
        </div>
        </div>
        <div>
          {/* <img className={styles.heroImage} src={data?.heroBanner?.image?.url} alt={data?.heroBanner?.image?.altText}></img> */}
          <Picture
            className={styles.heroImage}
            image={heroImages.heroImages['staticPages/Migration-Lucernex/Lx-NewHero.jpg']}
            rel="preload"
            alt={data?.heroBanner?.image?.altText}
          />
        </div>
     </div>
  </>
}

const Benefits = () => {
  return (
    <div className={styles.benefitContainer}>
      <div className={styles.benefitGridCompWrapper}>
        <div className={styles.benefitHeadingWrapper}>
          <h2 className={styles.benefitTitle}>{data?.benefits?.title}</h2>
          <p className={styles.benefitDesc}>{data?.benefits?.description}</p>
        </div>
        <div className={styles.featureGridWrapper}>
          {
            data?.benefits?.items?.map((item,index)=>
              <div key={index} className={styles.featureGridCard}>
              <div className={styles.featureBox}>
                <div className={styles.featureWrapper}>
                  <div className={styles.featureLogoWrapper}>
                    <img src={item?.logoUrl} alt={item?.altText} className="tw-min-h-[25px] "></img>
                  </div>
                  <h3 className={styles.featureGridTitle}>
                    {item?.title}
                  </h3>
                  <p className={styles.featureGridDesc}>{item?.description}</p>
                </div>
              </div>
              {item?.link ? (
                  <a href={item?.link?.url} className={styles.featureCardLinkWrapper}>
                    <span className={styles.featureCardLinkText}>{item?.link?.text}</span>
                    <img src={arrowRightBlue} alt='Right arrow'></img>
                  </a>
              ) : ""}
              </div>
            )
          }
        </div>      
      </div>
      <div className={styles.benefitProductsWrapper}>
        <p className={styles.benefitProductsText}>{data?.benefits?.info?.title}</p>
        <div className={styles.benefitProductsLogoList}>
          {
            data?.benefits?.info?.images?.map((image,index)=>
              <div key={index} className={styles.benefitProductsLogo}>
                <img src={image?.url} alt={image?.altText} className="tw-max-h-[90px] tw-max-w-[150px]"></img>
              </div>
            )
          }
        </div>
        <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.benefits?.info?.link?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src= {Dot}></img>
              </div>
            </a>
      </div>
    </div>
  )
}

const ThreeColComp = () => {
  return (
    <div className={styles.threeColContainer}>
      <div className={styles.threeColHeadWrapper}>
        <h2 className={styles.threeColTitle}>{data?.threeColTwoRowComp?.title}</h2>
        <p className={styles.threeColDesc + " tw-max-w-[1237px]"}>{data?.threeColTwoRowComp?.description}</p>
      </div>
      <div className={styles.threeColGridWrapper}>
        {
          data?.threeColTwoRowComp?.items?.map((item, index)=>
            <div key={index} className={styles.threeColGridBox + " tw-relative"}>
              <div className={styles.threeColGrid}>
                <div className={styles.threeColLogoWrapper}>
                  <img className="tw-max-h-[25px] tw-max-w-[25px]" src={item?.logoUrl} alt={item?.altText}></img>
                </div>
                <h3 className={styles.threeColGridTitle}>{item?.title}</h3>
                <p className={styles.threeColGridDesc}>{item?.description}</p>
              </div>
              <div className=" tw-absolute tw-bottom-[-18px]">
                <a href={item.link} className={styles.threColLinkWrapper}>
                    <span className={styles.threeColLinkText}>{item?.linkText}</span>
                    <img src={arrowRightBlue} alt='Right arrow'></img>
                </a>
              </div>
            </div>
          )
        }
      </div>
      {data?.threeColTwoRowComp?.link ? (
        <a href={data?.threeColTwoRowComp?.link?.url} className={styles.threColLinkWrapper}>
          <span className={styles.threeColLinkText}>{data?.threeColTwoRowComp?.link?.text}</span>
          <img src={arrowRightBlue} alt='Right arrow'></img>
        </a>
      ) : ""}
    </div>
  )
}

const FullWidthBanner = () => {
  return (
    <div className={styles.fullWidthBannerContainer}>
      <div className={styles.fullWidthBannerWrapper}>
        <div className={styles.fullWidthBannerLogoWrapper}>
          <div className={styles.fullWidthBannerLogo}>
            <img className={styles.fullWidthBannerImage} src={data?.fullWidthBanner?.image?.url} 
              alt={data?.fullWidthBanner?.image?.altText} loading="lazy"></img>
          </div>
        </div>
        <div className={styles.fullWidthBannerTextWrapper}>
          <p className={styles.fullWidthBannerTitle}>
            {data?.fullWidthBanner?.title}
          </p>
          <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.description}</p>
          <div>
            <p className={styles.fullWidthBannerName}>— {data?.fullWidthBanner?.name}</p>
            <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.position}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const LogoSlider = () => {
  return (
    <div className={styles.logoSliderContainer}>
      <div className={styles.logoStrip}>
      { data?.logoSlider?.logoList?.map((logo, index)=>
        <img key={index} className={styles.logoSliderImage} src={logo?.url} alt={logo?.altText}></img>
      )
      }
      </div>
    </div>
  )
}

const Features = () => {
  return (
    <div className={styles.featureContainer}>
      <div className={styles.featureGridCompWrapper}>
        <div className={styles.featureHeadingWrapper}>
          <img src={data?.features?.logo?.url} alt={data?.features?.logo?.altText} className={styles.heroLogo}></img>
          <h2 className={styles.featureTitle}>{data?.features?.title}</h2>
          <p className={styles.featureDesc + " tw-max-w-[1000px]"}>{data?.features?.description}</p>
        </div>
        <div className={styles.featureGridWrapper}>
          {
            data?.features?.items?.map((item,index)=>
              <div key={index} className={styles.featureGridCard}>
              <div className={styles.featureBox}>
                <div className={styles.featureWrapper}>
                  <div className={styles.featureLogoWrapper}>
                    <img src={item?.logoUrl} alt={item?.altText} className="tw-min-h-[25px] "></img>
                  </div>
                  <h3 className={styles.featureGridTitle}>
                    {item?.title}
                  </h3>
                  <p className={styles.featureGridDesc}>{item?.description}</p>
                </div>
              </div>
              {/* <a href={item?.link?.url} className={styles.featureCardLinkWrapper}>
                <span className={styles.featureCardLinkText}>{item?.link?.text}</span>
                <img src={arrowRightBlue} alt='Right arrow'></img>
              </a> */}
              </div>
            )
          }
        </div>       
      </div>
    </div>
  )
}

const LogoStrip = () => {
  return (
    <div className={styles.LogoStripContainer}>
      {
        data?.logoStrip?.map((img, index)=>(
          <img key={index} src={img?.url} altText={img?.altText}></img>
        ))
      }
    </div>
  )
}

const HeroBanner1 = () => {
  return <>
     <div className={styles.heroContainer1}>
        <div className={styles.hero1leftWrapper}>
          <h2 className={styles.hero1Title}>
            {data?.heroBanner1?.title}
          </h2>
          <p className={styles.hero1Desc}>{data?.heroBanner1?.description}</p>
          <div className={styles.hero1BtnWrapper}>
            <a href={'#mktoForm'} className={styles.hero1Btn1Wrapper}>
              <span className={styles.hero1Btn1Text}>{data?.heroBanner1?.link1?.text}</span>
              <div className={styles.hero1Btn1}>
                <img className={styles.hero1Btn1Img} src= {Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner1?.link2?.url} className={styles.hero1Btn2Wrapper}>
              <span className={styles.hero1Btn2Text}>{data?.heroBanner1?.link2?.text}</span>
              <img src= {arrowRight}></img>
            </a>
        </div>
        </div>
        <div>
          <img className={styles.hero1Image} src={data?.heroBanner1?.image?.url} alt={data?.heroBanner1?.image?.alt} loading="lazy"></img>
        </div>
     </div>
  </>
}

const customStylesPricing = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStylesPricing = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const PricingModal = ({ isOpen, onRequestClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStylesPricing} className={''}>
    <div style={{height:'560px', width:'1024px'}}>
      <button onClick={onRequestClose} style={closeButtonStylesPricing}>x</button>
      <div>
        <iframe className="pricing-iframe" src="https://app.calconic.com/api/embed/calculator/6488cb0028c7470029d3e5b5" sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups-to-escape-sandbox allow-popups" title="Calconic_ Calculator" name="Calconic_ Calculator" height="640px" width="1024px" scrolling="yes" ></iframe>
      </div>
    </div>
  </Modal>
);

const LinkStrip = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenROI, setModalIsOpenROI] = useState(false);

  const openModalPricing = () => {
    setModalIsOpen(true);
  };

  const closeModalPricing = () => {
    setModalIsOpen(false);
  };
  const openModalROI = () => {
    setModalIsOpenROI(true);
  };

  const closeModalROI = () => {
    setModalIsOpenROI(false);
  };
  return (
    <>
      <div className={styles.LinkStripContainer}>
        {/* <a href={data?.LinkStrip?.url} className={styles.LinkStripWrapper} onClick={() => openModal()}> */}
          {/* <span className={styles.LinkStripText}>{data?.LinkStrip?.text}</span> */}
          {/* <img src={arrowRight} alt='Right arrow'></img> */}
          {/* <div className={styles.linkStripLeftWrapper} onClick={() => openModalPricing()}>
            <div className={styles.LinkStripWrapper}>
              <span className={styles.LinkStripText}>{data?.LinkStrip?.link1?.text}</span>
              <img src={arrowRight} alt='Right arrow'></img>
            </div>

          </div> */}
          <div className={styles.linkStripRightWrapper} onClick={() => openModalROI()}>
            <div className={styles.LinkStripWrapper}>
              <span className={styles.LinkStripText}>{data?.LinkStrip?.link2?.text}</span>
              <img src={arrowRight} alt='Right arrow'></img>
            </div>
          </div>
        {/* </a> */}
      </div>
      <PricingModal
        isOpen={modalIsOpen}
        onRequestClose={closeModalPricing}
      />
      <ROICalculatorModal
        isOpen={modalIsOpenROI}
        onRequestClose={closeModalROI}
      />
    </>
  )
}

const Resource = () => {
  return (
    <div className={styles.resourceContainer}>
      <div className={`${styles.resourceHeadingWrapper} container`}>
        <h2 className={styles.resourceTitle}>{data?.resources?.title}</h2>
        <a href={data?.resources?.link?.url} className={styles.resourceLinkWrapper}>
          <span className={styles.resourceLinkText}>{data?.resources?.link?.text}</span>
          <img src={arrowRightBlue} alt='arrow button'></img>
        </a>
      </div>
      <div className={styles.resourceWrapper}>
        <div className={`${styles.resourceCardWrapper} container`}>
          <a href={data?.resources?.resourceList?.resource1?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource1?.image?.url} alt={data?.resources?.resourceList?.resource1?.image?.altText}/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource1?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource1?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource1?.button?.text}</div>
              </div>
            </div>
          </a>
          <a href={data?.resources?.resourceList?.resource2?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource2?.image?.url} alt={data?.resources?.resourceList?.resource2?.image?.altText}/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource2?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource2?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource2?.button?.text}</div>
              </div>
            </div>
          </a>
          <div className={styles.resourceMultiCardWrapper}>
            {
              data?.resources?.resourceList?.list?.map((item, index)=>
              <a key={index} href={item?.link} className={styles.resourceMultiCard}>
              <div>
                <img className={styles.resourceMultiCardImg} src={item?.image?.url} alt={item?.image?.altText}></img>
                <div className={styles.resourceCardBtnWrapper + " md:!tw-hidden !tw-flex tw-mt-[15px] tw-w-fit"}>
                    <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                </div>
              </div>
              <div className={styles.resourceMultiCardTextWrapper}>
                <p className={styles.resourceMultiCardText}>{item?.title}</p>
                <div className={styles.resourceCardBtnWrapper + " md:!tw-flex !tw-hidden"}>
                  <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                </div>
              </div>
            </a>
              )
            }
          </div>
        </div>
      </div>
      <div className={styles.walkthroughWrapper}>
        <h2 className={styles.walkthroughTitle}>{data?.resources?.walkthrough?.title}</h2>
        <div className={styles.walkthroughFlexWrapper}>
          <VideoGrid />
        </div>
      </div>
    </div>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'hidden'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const VideoModal = ({ isOpen, onRequestClose, wistiaId }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
    <div>
      <button onClick={onRequestClose} style={closeButtonStyles}>x</button>
      <div
        className={`wistia_embed wistia_async_${wistiaId} videoFoam=true`}
        style={{height:'360px', width:'640px'}}
      >
        &nbsp;
      </div>
    </div>
  </Modal>
);

const VideoGrid = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openModal = (wistiaId) => {
    setCurrentVideo(wistiaId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo(null);
  };

  return (
    <>
      {data?.resources?.walkthrough?.items?.map((video, index) =>
        <div className={styles.walkthroughCardWrapper} onClick={() => openModal(video.wistiaId)}>
          <img src={video?.logo?.url} alt={video?.logo?.altText}></img>
          <div className={styles.walkthroughCardText}>
            <p className={styles.walkthroughCardTitle}>{video?.title}</p>
            <p className={styles.walkthroughCardDesc}>{video?.description}</p>
          </div>
        </div>
      )
      }
      <VideoModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        wistiaId={currentVideo}
      />
    </>
  );
};

const Question = ({item}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={styles.supportListWrapper}>
      <div onClick={()=>setOpen(!open)} className={styles.supportListTitleWrapper}>
        <img src={open ? arrowUpBlue : arrowDownBlue} alt='arrow'></img>
        <h3 className={styles.supportListTitle}>{item?.title}</h3>
      </div>
      {open && <div className={styles.supportListDescWrapper}>
          {
            item?.descList?.map((i, index)=>
              <p key={index} className={styles.supportListDesc} dangerouslySetInnerHTML={{ __html: i?.text }}></p>
            )
          }
        </div>
      }
    </div>
  )
}

const Support = () => {
  return (
    <div className={styles.supportContainer}>
      <div className={styles.supportHeadWrapper}>
        <img className={styles.supportLogo} src={data?.support?.logo?.url} alt={data?.support?.logo?.altText}></img>
        <h2 className={styles.supportTitle}>{data?.support?.title}</h2>
        <div className={styles.supportQListWrapper}>
        {
          data?.support?.list?.map((item, index)=>
            <Question key={index} item={item}/>
          )
        }
      </div>
      </div>
      <div className={styles.supportContactWrapper}>
        <p className={styles.supportContactText}>{data?.support?.contact?.title}</p>
        <a className={styles.supportContactLink} href={data?.support?.contact?.link?.url}>
          <span className={styles.supportContactLinkText}>{data?.support?.contact?.link?.text}</span>
          <img src={arrowRightBlue} alt='arrow right'></img>
        </a>
      </div>
    </div>
  )
}

const Design = () => {
  return (
    <div className={styles.designContainer}>
      <div className={styles.designTextWrapper}>
        <div className={styles.designTitleWrapper}>
          <h2 className={styles.designTitle}>{data?.design?.title}</h2>
          <div className={styles.designDescWraper}>
            <p className={styles.designDesc}>{data?.design?.description}</p>
            <a href={data?.design?.link?.url} className={styles.designLink}>
              <span className={styles.designLinkText}>{data?.design?.link?.text}</span>
              <img src={arrowRightBlue} alt='arrow img'></img>
            </a>
          </div>
        </div>

      </div>
      <div className={styles.designLogoContainer}>
        <div className={styles.designLogoStrip}>
          {
            data?.design?.productList?.map((item, index)=>
              <a href={item?.link}  key={index} className={styles.designLogoWrapper}>
                <img className={styles.designLogo} src={item?.url} alt={item?.altText}></img>
              </a>
            )
          }
        </div>
      </div>
    </div>
  )
}

const customStylesROI = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStylesROI = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const ROICalculatorModal = ({ isOpen, onRequestClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStylesROI} className={''}>
    <div style={{height:'560px', width:'1024px'}}> 
      <button onClick={onRequestClose} style={closeButtonStylesROI}>x</button>
      {/* <div> */}
        <ROICalculator
              data={data.ROICalculator}
        />
      {/* </div> */}
    </div>
  </Modal>
);

export default function Mc() {
  const heroImages = useHeroImages();
  const formImages = useFormImages()
  const pageDataPath = getPageDataJsonPath('/products/lucernex')
  const contentEntity = {
    "uuid": "abfd837b-6954-4cb8-a9c1-9c100cccda3b",
    "nid": 4046,
    "fieldEmbedCode": "<script src=\"//app-abc.marketo.com/js/forms2/js/forms2.min.js\"></script>\r\n<form id=\"mktoForm_1693\"></form>\r\n<script>MktoForms2.loadForm(\"//app-abc.marketo.com\", \"167-BOY-362\", 1693, function(form) {\r\n    dataLayer.push({\r\n      'event': 'gaTriggerEvent',\r\n      'gaCategory': 'CTA Engagement',\r\n      'gaAction': 'Form Load',\r\n      'gaLabel': 'Demo Request',\r\n    });\r\n    form.onSuccess(function(values, followUpUrl) {\r\n      dataLayer.push({\r\n        'event': 'gaTriggerEvent',\r\n        'gaCategory': 'CTA Engagement',\r\n        'gaAction': 'Form Submission',\r\n        'gaLabel': 'Demo Request',\r\n      });\r\n    });\r\n  });\r\n</script>",
    "title": "Request A Demo ",
    "fieldFormHeader": "Request A Demo",
    "fieldHideTitle": false,
    "fieldShowTitleFullWidth": null,
    "fieldFormText": null,
    "fieldFormImage": {
      "alt": 'A real estate professional using Lucernex software on a tablet'
    },
    "relationships": {
        "fieldFormImage": {
        },
        "fieldFormImageWithCover": formImages['staticPages/form-face.png'],
        "fieldMediaImage": {
            "fieldImage": {
                "alt": "bg"
            },
            "relationships": {
                "field_image": formImages['staticPages/form-bg.jpg'],
            }
        }
    },
    "type": {
        "id": "marketo_forms"
    },
    "fieldVersions": "advanced",
    "fieldCtaUrl": null
  }
  return (
    <>
      <SEO
        meta={
          [
            { 'name': 'title', 'content': "Lucernex Real Estate Management Software | Accruent" },
            { 'name': 'description', 'content': "Simplify real estate management with Lucernex, ensuring compliance and efficiency in site planning, construction, and lease administration." }
          ]
        }
        heroImage={heroImages['staticPages/Migration-Lucernex/Lx-NewHero.jpg']}
        preloads= {[pageDataPath]}
        lang="en"
        pageUrl="/products/lucernex"
        alternatives={[
          {
              "href": "/products/lucernex",
              "hreflang": "en"
          },
          {
              "href": "/uk/products/lucernex",
              "hreflang": "en-gb"
          },
          {
              "href": "/products/lucernex",
              "hreflang": "x-default"
          }
      ]}
      />
      <HeroBanner heroImages={heroImages}/>
      <LogoStrip />
      <Benefits/>
      <ThreeColComp/>
      <FullWidthBanner/>
      <LogoSlider/>
      <Features/>
      <HeroBanner1/>
      <LinkStrip/>
      <Resource/>
      <Support/>
      <div id="mktoForm">
        <ContentMarketoForm
          entity={contentEntity}
          locale="en"
          pageTitle="Lucernex: Real Estate Management Software"
          formFullWidth={false}
        />
      </div>
      <Design/>
    </>
  );
}